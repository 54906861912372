import { baseRequestWithHeaders, baseRequestWithUserHeaders } from './baseRequest';

export const findUser = (phone) => {
    const url = `/new-ops/labs-user-details/${phone}`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method);

};
export const uploadFileForTranscription = (files, prescriptionId, token) => {
    const url = `/labs/prescriptions/multiple-files`;
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
        data.append('file', files[i]);
    }

    if (prescriptionId) {
        data.append('prescriptionId', prescriptionId);
    }

    const method = 'POST';
    return baseRequestWithUserHeaders(url, method, null, data, token);
};

export const getUploadedPrescriptions = (token) => {
    const url = `/labs/prescriptions`;
    const method = 'GET';
    return baseRequestWithUserHeaders(url, method, null, null, token);
};

export const removeFileFromPrescription = (prescriptionId, fileId, token) => {
    const url = `/labs/prescriptions/file/remove`;
    const method = 'POST';
    const data = { fileId, prescriptionId };
    return baseRequestWithUserHeaders(url, method, null, data, token);
};

export const addAddressForUser = (address, pincode, locality, title, token) => {
    const url = `/labs/address`;
    const method = 'POST';
    const data = { address, locality, pincode, title };
    return baseRequestWithUserHeaders(url, method, null, data, token);
};

export const listAddresses = (token) => {
    const url = `/labs-v2/address`;
    const method = 'GET';
    return baseRequestWithUserHeaders(url, method, null, null, token);
};

export const addPatientForUser = (name, age, gender, phone, token) => {
    const url = `/labs-v2/patients`;
    const method = 'POST';
    const data = { name, age, gender, phone };
    return baseRequestWithUserHeaders(url, method, null, data, token);
};

export const listPatients = (token) => {
    const url = `/labs-v2/patients`;
    const method = 'GET';
    return baseRequestWithUserHeaders(url, method, null, null, token);
};

export const createOrderForAhc = (requestId, partnerId, testId, centerId) => {
    const url = `/new-ops/ahc/requests/create-order`;
    const method = 'POST';
    const data = { requestId,  partnerId, testId, centerId};
    return baseRequestWithHeaders(url, method, null, data);
};

export const listPartnersForAhc = (requestId) => {
    const url = `/new-ops/ahc/requests/list-partners-ahc?requestId=${requestId}`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method, null, null);
};

export const listSlots = (token) => {
    const url = `/labs/slots`;
    const method = 'GET';
    return baseRequestWithUserHeaders(url, method, null, null, token);
};

export const createTranscriptionRequest = (patientId, addressId, slotId, collectionDate, prescriptionId, token) => {
    const url = `/labs/prescriptions/submit-request`;
    const method = 'POST';
    const data = { patientId, addressId, slotId, collectionDate, prescriptionId, requestedByType: 'ops' };

    return baseRequestWithUserHeaders(url, method, null, data, token);
};

export const listPatientsByOps = (userId) => {
    const url = `/new-ops/labs/lab-cart/list-patients/${userId}`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method, null, null);
};

export const listAddressesByOps = (userId) => {
    const url = `/new-ops/labs/lab-cart/list-address/${userId}`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method, null, null);
};

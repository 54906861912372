import React, { useEffect, useState, useCallback } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Row,
  Col,
  Label,
  Alert,
} from 'reactstrap';
import Select from 'react-select';
import { Async } from 'react-select';
import { TimePicker } from 'antd';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { BoldText, RegularText } from '../common/Text';
import { FaEdit } from 'react-icons/fa';

import {
  getVerticals,
  searchDoctors,
  shiftAppointment,
  getDoctorSlots as getDocSlots,
} from '../../services/api/offline-consult';

import moment from 'moment';
import LoadingComponent from '../../components/common/LoadingComponent';

const EDIT_APPOINTMENT_REASONS = [
  { reason: '', label: 'Select Reason' },
  { reason: 'Doctor is in emergency', label: 'Doctor is in emergency' },
  { reason: 'Slot not available', label: 'Slot not available' },
  { reason: 'Consultation fee changed', label: 'Consultation fee changed' },
  {
    reason: 'User requested for alternate slot',
    label: 'User requested for alternate slot',
  },
  {
    reason: 'Doctor sits in multiple branches',
    label: 'Doctor sits in multiple branches',
  },
  {
    reason: 'Doctor moved to the other branch',
    label: 'Doctor moved to the other branch',
  },
  {
    reason: 'Doctor on leave',
    label: 'Doctor on leave',
  },
  {
    reason: 'Doctor is out of station',
    label: 'Doctor is out of station',
  },
  {
    reason: 'Doctor is not available as per the requested slots',
    label: 'Doctor is not available as per the requested slots',
  },
];

const SectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.625rem;
`;

const SectionHeader = styled(BoldText)`
  font-size: 1.2rem;
  color: #4b4f5b;
`;

const SectionBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.625rem;
`;
const Warning = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.625rem;
  padding-top: 1rem;
  color: #ffaf3c;
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 0.25rem;
`;

const DetailName = styled.div`
  font-size: 1rem;
  width: ${(props) => (props.fullWidth ? '20rem' : '8rem')};
  color: #4c4c4c;
`;

const DoctorSlotBox = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const DetailText = styled(RegularText)`
  flex: 1;
  font-size: 1rem;
  color: ${(props) => props.color || '#787778'};
  margin-left: 0.25rem;
`;

const RescheduleAppointMentRequest = (props) => {
  const {
    shiftingDetails,
    isOpen,
    closeShiftingModal,
    fetchTableData,
    showEditDoctorSlotsModal,
    showEditDoctorSlots,
  } = props;
  console.log(props);
  const [selectedVertical, setSelectedVertical] = useState({});
  const [allVerticals, setAllVerticals] = useState([]);
  const [requestId, setRequestId] = useState('');
  const [verticalLoading, setVerticalLoading] = useState(false);
  const [doctorsData, setDoctorsData] = useState([]);
  const [appDate, setAppDate] = useState('');
  const [dateKey, setDateKey] = useState('');
  const [selectedSlot, setSelectedSlot] = useState('');
  const [appointmentStartTime, setAppointmentStartTime] = useState(null);
  const [appointmentEndTime, setAppointmentEndTime] = useState(null);
  const [centerAddress, setCenterAddress] = useState(null);
  const [editReason, setEditReason] = useState('');
  const [editComment, setEditComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [slotType, setSlotType] = useState('fixed');
  const [warning, setWarning] = useState('');
  const [doctorSlots, setDoctorSlots] = useState(null);
  const [showDoctorNewSlots, setShowDoctorNewSlots] = useState(false);
  const [externalSlotInfo, setExternalSlotInfo] = useState(null);
  const [doctorNewSlot, setDoctorNewSlot] = useState({
    modal: false,
    data: [],
    date: ''
  });

  const checkWhetherApptDateWithinSlots = (date, valueModified = false) => {
    let isWithinSlots = false;
    const apptDate = new Date(date);
    const apptDOW = apptDate.getDay() + 1;
    const apptTime = moment(apptDate).format('HH:mm:ss');
    console.log('checkWhetherApptDateWithinSlots', {
      date,
      apptDate,
      apptDOW,
      apptTime,
    });
    const apptFormatDate = moment(date).format('YYYY-MM-DD')
    console.log('checkWhetherApptDateWithinSlots', { date, apptDate, apptDOW, apptTime, apptFormatDate});
    const correctSlots = !showDoctorNewSlots ? doctorSlots.filter(slot => slot.dow === apptDOW) : doctorSlots.filter(slot => slot.formattedDateKey === apptFormatDate);
    console.log('checkWhetherApptDateWithinSlotsData', { correctSlots});

    if(!showDoctorNewSlots){
    for (let slot of correctSlots) {
      for (let slotValue of slot.values) {
        const startTime = moment(slotValue.startTime, 'hh:mm A').format(
          'HH:mm:ss'
        );
        const endTime = moment(slotValue.endTime, 'hh:mm A').format('HH:mm:ss');
        if (apptTime >= startTime && apptTime <= endTime) isWithinSlots = true;
      }
    }}
    else{
      if(correctSlots[0] && correctSlots[0].slots){
        for(let slotValue of correctSlots[0].slots) {
        const startTime = moment(slotValue.slotKey, 'hh:mm A').format('HH:mm:ss');
        const endTime = moment(slotValue.slotKeyEndTime, 'hh:mm A').format('HH:mm:ss');
        if(apptTime >= startTime && apptTime <= endTime){
          isWithinSlots = true;
          if(slotValue.externalSlotInfo){
            setExternalSlotInfo({...slotValue.externalSlotInfo})  
          }
        }
      }
    }}
    setWarning(
      !isWithinSlots
        ? 'The selected Appointment Date/Time is not within the Available Doctor Slots, edit the slots or select different date/time'
        : ''
    );
    if (!isWithinSlots && valueModified) {
      toast.error(
        'The selected Appointment Date/Time is not within the Available Doctor Slots, edit the slots or select different date/time'
      );
      setDoctorSlots(null);
      if(!showDoctorNewSlots){
        showEditDoctorSlotsModal(shiftingDetails.doctorId);
      }
    }
    return isWithinSlots;
  };

  const getDoctorSlots = useCallback(
    (doctorId, networkCenterId) => {
      let { requestId } = shiftingDetails;
      if (doctorId && networkCenterId) {
        requestId = null;
      }

      getDocSlots({ requestId, doctorId, networkCenterId })
        .then((res) => {
          console.log({ slots: res.data, showSlot: res.data.showSlot }, 'getDoctorSlots');
          setLoading(true);
          if(res.data.showSlot){
            setSlotType('walk-in');
          }
          else{
            setSlotType('fixed');
        }
          setShowDoctorNewSlots(res.data.showSlot);  
          setDoctorSlots(res.data.slots);
          checkWhetherApptDateWithinSlots(shiftingDetails.uAppointmentDate);
        })
        .catch((err) => {
          console.log({ err });
        })
        .finally(() => setLoading(false))
    },
    [shiftingDetails]
  );

  useEffect(() => {
    if (shiftingDetails && allVerticals && allVerticals.length > 0) {
      console.log(shiftingDetails);
      setRequestId(shiftingDetails.requestId);
      const vertical = allVerticals.find(
        (item) => item.value == shiftingDetails.verticalId
      );
      setSelectedVertical(vertical);
    }
    if (shiftingDetails && shiftingDetails.doctorId) {
      setDoctorsData({
        label: `${shiftingDetails.doctorName} - ${shiftingDetails.networkCenterName} - ${shiftingDetails.networkCenterAddress}`,
        value: shiftingDetails.doctorId,
        centerId: shiftingDetails.networkCenterId,
        fees: shiftingDetails.total_charges,
      });
    }
    if (shiftingDetails && shiftingDetails.uAppointmentDate) {
      console.log(shiftingDetails,'shigtiksk')
      const date = shiftingDetails.uAppointmentDate.split(' ')[0];
      setAppDate(date);
      const dateKey = moment(date, 'YYYY-MM-DD').format('YYYYMMDD');
      setDateKey(dateKey);
    }
    if(shiftingDetails && shiftingDetails.networkCenterAddress){
      setCenterAddress(shiftingDetails.networkCenterAddress);
    }
  }, [shiftingDetails, allVerticals]);

  useEffect(() => {
    fetchVerticals();
  }, []);

  useEffect(() => {
    getDoctorSlots();
  }, [getDoctorSlots, showEditDoctorSlots]);

  const fetchVerticals = () => {
    setVerticalLoading(true);
    getVerticals().then((response) => {
      const arr = [];
      response.data.forEach((element) => {
        arr.push({ value: element.verticalId, label: element.verticalName });
      });
      setAllVerticals(arr);
      setVerticalLoading(false);
    });
  };

  const searchDoctor = (val) => {
    const verticalId = selectedVertical.value;
    return searchDoctors(val, verticalId)
      .then((response) => {
        return response.data || [];
      })
      .then((result) =>
        result.map((ele) => {
          return {
            label: `${ele.doctorName} - ${ele.networkCenterTitle} - ${ele.centerCity} - ${ele.networkSourceName}`,
            value: ele.doctorId,
            centerId: ele.networkCenterId,
            fees: ele.opdCharges,
            centerAddress: ele.centerAddress
          };
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDateChange = (event) => {
    const date = event.target.value;
    if (!date) {
      return;
    }
    const dateKey = moment(date, 'YYYY-MM-DD').format('YYYYMMDD');
    setAppDate(date);
    console.log(date);
    setDateKey(dateKey);
  };

  const shiftAppointments = () => {
    setLoading(true);
    const { requestId } = shiftingDetails;
    try {
      const doctorId = doctorsData.value || false;
      const centerId = doctorsData.centerId || false;
      if (!doctorId || !centerId || !editReason || !dateKey || !selectedSlot) {
        toast.error('Incorrect Selection');
        return;
      }
      if (
        slotType === 'walk-in' &&
        !(appointmentStartTime || appointmentEndTime)
      ) {
        toast.error('Incorrect Selection');
        return;
      }
      const slotCheckResult = checkWhetherApptDateWithinSlots(
        `${appDate} ${
          slotType === 'fixed' ? selectedSlot : appointmentStartTime
        }`
      );
      if (!slotCheckResult) {
        toast.error(
          'The selected Appointment Date/Time is not within the Available Doctor Slots, edit the slots or select different date/time'
        );
        return;
      }
      let timeRange =
        appointmentStartTime && appointmentEndTime && slotType == 'walk-in'
          ? `${appointmentStartTime}-${appointmentEndTime}`
          : null;
      return shiftAppointment(
        requestId,
        doctorId,
        centerId,
        editReason,
        editComment,
        dateKey,
        selectedSlot,
        slotType,
        timeRange,
        externalSlotInfo
      )
        .then((res) => {
          if (res.message === 'success') {
            closeShiftingModal();
            toast.success(`Success`);
            fetchTableData();
            setLoading(false);
          } else {
            const err = new Error('Invalid response');
            err.data = res;
            throw err;
          }
        })
        .catch((err) => {
          console.log({ err, response: err.data });
          setLoading(false);
          toast.error(`Failure! ${err.data.errorMessage}`);
        });
    } catch (e) {
      toast.error(`Failure!`);
      console.log({ e });
      setLoading(false);
    }
  };

  const hanldeVerticalChange = (vertical) => {
    setSelectedVertical(vertical);
  };

  const handleDoctorSelection = (doctorsData) => {
    console.log(doctorsData,'doctorsData123')
    setDoctorsData(doctorsData);
    getDoctorSlots(doctorsData.value, doctorsData.centerId);
    setCenterAddress(doctorsData.centerAddress);
  };

  console.log('sgasgabnk', shiftingDetails);

  return (
    <Modal
      //   onOpened={this.onModalOpened}
      centered
      isOpen={isOpen}
      onCancel={closeShiftingModal}
      toggle={closeShiftingModal}
    >
      <ModalHeader className="revamp-modal-header" toggle={closeShiftingModal}>
        Reschedule Request ({requestId})
      </ModalHeader>
      <ModalBody>
        <form>
          <FormGroup>
          {showDoctorNewSlots && <Warning>{'The appointment rescheduled request for this centre will be auto confirmed since it is API driven, please ensure patient is aligned for this slot before rescheduling.'}</Warning>}
            <Row style={{marginTop: '16px'}}>
              <Col className="col-3" style={{}}>
                <Label
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: '500',
                    width: 'max-content',
                  }}
                >
                  Select Vertical:
                </Label>
              </Col>
              <Col>
                <Select
                  className="dynamic-select"
                  isSearchable={true}
                  onChange={(e) => {
                    hanldeVerticalChange(e);
                  }}
                  value={selectedVertical}
                  options={allVerticals}
                  isLoading={verticalLoading}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: '500',
                    width: 'max-content',
                  }}
                >
                  Search Doctor:
                </Label>
              </Col>
              <Col>
                <Async
                  loadOptions={searchDoctor}
                  onChange={(e) => {
                    handleDoctorSelection(e);
                  }}
                  value={doctorsData}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: '500',
                    width: 'max-content',
                  }}
                >
                  Center Address:
                </Label>
              </Col>
              <Col>
              <Label
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: '300',
                    padding:'4px'
                  }}
                >
                  {centerAddress ?  centerAddress : 'Unavailable'}
                </Label>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: '500',
                    width: 'max-content',
                  }}
                >
                  Consultation Fee:
                </Label>
              </Col>
              <Col>
                <input
                  className="form-control"
                  style={{
                    backgroundColor: '#FAFBFC',
                    borderRadius: '15px',
                    cursor: 'not-allowed',
                  }}
                  type="number"
                  value={doctorsData.fees}
                  disabled
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col
                className="col-3"
                style={{
                  borderRadius: '15px',
                }}
              >
                <Label
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: '500',
                    width: 'max-content',
                  }}
                >
                  Appointment Date:
                </Label>
              </Col>
              <Col>
                <input
                  className="form-control"
                  type="date"
                  value={appDate}
                  onChange={handleDateChange}
                  style={{ borderRadius: '15px' }}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col style={{ borderRadius: '15px' }} className="col-3">
                <Label
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: '500',
                  }}
                >
                  Slot type:
                </Label>
              </Col>
              <Col>
                <select
                  value={slotType}
                  onChange={(e) => {
                    setSlotType(e.target.value);
                  }}
                  className="form-control"
                >
                { !showDoctorNewSlots &&  <option value="fixed" key="fixed">
                    Fixed
                  </option>
                 }
                  <option value="walk-in" key="walk-in">
                    Walk In
                  </option>
                </select>
              </Col>
            </Row>
          </FormGroup>
          {slotType == 'fixed' && (
            <FormGroup>
              <Row>
                <Col style={{ borderRadius: '15px' }} className="col-3">
                  <Label
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: '500',
                      width: 'max-content',
                    }}
                  >
                    Appointment Time:
                  </Label>
                </Col>
                <Col>
                  <TimePicker
                    className="w-full"
                    onChange={(_, date) => {
                      setSelectedSlot(date);
                      if (appDate && date) {
                        checkWhetherApptDateWithinSlots(
                          `${appDate} ${date}`,
                          true
                        );
                      }
                    }}
                    format="hh:mm:00 A"
                    defaultValue={
                      selectedSlot
                        ? moment(selectedSlot, 'hh:mm:00 A')
                        : undefined
                    }
                  />
                </Col>
              </Row>
            </FormGroup>
          )}
          {slotType == 'walk-in' && (
            <FormGroup>
              <Row>
                <Col style={{ borderRadius: '15px' }} className="col-3">
                  <Label
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: '500',
                      width: 'max-content',
                    }}
                  >
                    Appointment Time:
                  </Label>
                </Col>
                <Col className="col-4">
                  <TimePicker
                    className="w-full"
                    onChange={(_, date) => {
                      console.log(date);
                      const newTime =
                        date.substring(0, 3) +
                        date.substring(3, 5) +
                        ' ' +
                        date.substring(9);
                      setAppointmentStartTime(newTime);
                      setSelectedSlot(date);
                      if (appDate && date) {
                        checkWhetherApptDateWithinSlots(
                          `${appDate} ${date}`,
                          true
                        );
                      }
                    }}
                    placeholder="Start Time"
                    format="hh:mm:00 A"
                    defaultValue={
                      appointmentStartTime
                        ? moment(appointmentStartTime, 'hh:mm:00 A')
                        : undefined
                    }
                  />
                </Col>
                <Col className="col-4">
                  <TimePicker
                    className="w-full"
                    onChange={(_, date) => {
                      const newTime =
                        date.substring(0, 3) +
                        date.substring(3, 5) +
                        ' ' +
                        date.substring(9);
                      setAppointmentEndTime(newTime);
                    }}
                    format="hh:mm:00 A"
                    placeholder="End Time"
                    defaultValue={
                      appointmentEndTime
                        ? moment(appointmentEndTime, 'hh:mm:00 A')
                        : undefined
                    }
                  />
                </Col>
              </Row>
            </FormGroup>
          )}
          <FormGroup>
            <Row>
              <Col style={{ borderRadius: '15px' }} className="col-3">
                <Label
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: '500',
                    width: 'max-content',
                  }}
                >
                  Reschedule Reason:
                </Label>
              </Col>
              <Col>
                <select
                  value={editReason}
                  onChange={(e) => {
                    setEditReason(e.target.value);
                  }}
                  className="form-control"
                >
                  {EDIT_APPOINTMENT_REASONS.map(({ reason, label }) => (
                    <option value={reason} key={reason}>
                      {label}
                    </option>
                  ))}
                </select>{' '}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col style={{ borderRadius: '15px' }} className="col-3">
                <Label
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: '500',
                    fontSize: '17px',
                  }}
                >
                  Remarks
                </Label>
              </Col>
              <Col>
                <textarea
                  className="form-control"
                  value={editComment}
                  rows={1}
                  onChange={(e) => setEditComment(e.target.value)}
                />
              </Col>
            </Row>
          </FormGroup>
        </form>
        {warning && <Warning>{warning}</Warning>}
        {doctorSlots ? (
          <SectionInnerContainer>
            <SectionHeader>
              Doctor Slots &nbsp;
              { !showDoctorNewSlots && <FaEdit
                className="button"
                onClick={() => {
                  setDoctorSlots(null);
                  showEditDoctorSlotsModal(doctorsData.value);
                }}
              />}
            </SectionHeader>
            <SectionBody>
            {!loading && !showDoctorNewSlots && doctorSlots.map((slot) => (
                <DetailRow key={slot.day}>
                  <DetailName>{`${slot.day}:`}</DetailName>
                  <DetailText>
                    {slot.values
                      .map((value) => `${value.startTime} - ${value.endTime}`)
                      .join(', ')}
                  </DetailText>
                </DetailRow>
              ))}

            {!loading && showDoctorNewSlots && doctorSlots.map((slot) => (
                  <DetailRow key={slot.dateKey}>
                    <DetailName>{`${slot.formattedDateKey}:`}</DetailName>
                    <DetailText>
                      {slot.slots.slice(0,4)
                        .map((value) => `${value.timings}`)
                        .join(', ')} { slot.slots.length > 4 && <span style={{ color: 'green', cursor: 'pointer', fontWeight: 700}} onClick={() => setDoctorNewSlot({modal: true, data: slot.slots, date: slot.formattedDateKey})}>...more</span>}
                    </DetailText>
                  </DetailRow>
                ))}
            </SectionBody>
          </SectionInnerContainer>
        ) : (
          <Button color="link" onClick={ () => getDoctorSlots(doctorsData.value, doctorsData.centerId)}>
            Show Doctor Slots
          </Button>
        )}
        <Modal
            isOpen={doctorNewSlot.modal}
            onCancel={() => setDoctorNewSlot({ modal: false, data: [] })}
          >
  <ModalHeader
    className="bg-primary"
    toggle={() => setDoctorNewSlot({ modal: false, data: [] })}
  >
    Doctor Slots
  </ModalHeader>
  <ModalBody>
    <DetailRow>
      <DetailName>{`${doctorNewSlot.date}:`}</DetailName>
      <DoctorSlotBox>
        {doctorNewSlot.data.map((value) => (
          <p
            style={{
              padding: "6px",
              backgroundColor: "skyblue",
              borderRadius: "8px",
              margin: "4px",
            }}
          >
            {value.timings}
          </p>
        ))}
      </DoctorSlotBox>
    </DetailRow>
  </ModalBody>
</Modal>
      </ModalBody>
      <ModalFooter
        style={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        {loading ? <LoadingComponent color={'black'} /> : <></>}
        <Button
          style={{ backgroundColor: '#714FFF', width: '40%' }}
          disabled={loading || !selectedSlot || !editReason || !dateKey}
          onClick={() => shiftAppointments()}
        >
          Shift Appointment
        </Button>
        <Button
          style={{
            width: '40%',
            backgroundColor: '#FFFFFF',
            color: '#714FFF',
            border: '1px solid #714FFF',
          }}
          onClick={() => closeShiftingModal()}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RescheduleAppointMentRequest;

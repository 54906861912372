import React, { useState, useEffect } from 'react';
import FileSaver from 'file-saver';
import jsonToCsv from '../../utils/jsonToCsv';
import { getAllOfflineRequests } from '../../services/api/offline-consult';

export default class DownloadCsv extends React.Component {
  state = {
    isLoading: false,
    dots: '.',
  };

  componentDidUpdate(_, prevState) {
    if (this.state.isLoading && !prevState.isLoading) {
      this.startLoadingAnimation();
    }
  }

  startLoadingAnimation = () => {
    let count = 1;
    this.loadingInterval = setInterval(() => {
      const dots = '.' + '.'.repeat(count % 3); // Ensures first dot is fixed, adding others after
      this.setState({ dots });
      count++;
    }, 500);
  };

  stopLoadingAnimation = () => {
    clearInterval(this.loadingInterval);
    this.setState({ isLoading: false, dots: '.' });
  };

  handleClick = () => {
    this.setState({ isLoading: true });

    const downloadCsv = (jsonArray, fileName) => {
      const csv = jsonToCsv(jsonArray);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      FileSaver.saveAs(blob, fileName);
      this.stopLoadingAnimation();
    };

    if (this.props.isCashless) {
      this.props.callback().then((response) => {
        downloadCsv(response.result, this.props.fileName || 'cashless_vaccination.csv');
      });
    } else if (this.props.isCashlessDental) {
      this.props.callback().then((response) => {
        downloadCsv(response.data.rows, this.props.fileName || 'cashless_dental.csv');
      });
    } else if (this.props.isCashlessVision) {
      this.props.callback().then((response) => {
        downloadCsv(response.data.visionRequests, this.props.fileName || 'cashless_vision.csv');
      });
    } else if (this.props.jsonArray) {
      downloadCsv(this.props.jsonArray, this.props.fileName || 'exports.csv');
    } else {
      const params = { ...this.props.params, draw: 100000 };
      getAllOfflineRequests(params).then((jsonArray) => {
        if (jsonArray) {
          downloadCsv(jsonArray.result, 'offline_request.csv');
        } else {
          this.stopLoadingAnimation();
        }
      });
    }
  };

  render() {
    const buttonStyles = {
      background: '#714FFF',
      borderColor: '#714FFF',
      margin: '0px',
      borderRadius: '5px',
      border: '0px',
      color: 'white',
      padding: '12px 12px',
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'Inter, sans-serif',
      justifyContent: 'center',
      alignItems: 'center',
      width: '180px', // Fixed width
      height: '40px', // Fixed height
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'flex',
      position: 'relative',
    };

    return (
      <button
        style={buttonStyles}
        onClick={this.handleClick}
        disabled={this.state.isLoading}
      >
        {this.state.isLoading ? `Downloading${this.state.dots}` : `Download CSV`}
      </button>
    );
  }
}
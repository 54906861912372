import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  FaBuffer,
  FaGlobe,
  FaChevronLeft,
  FaBriefcaseMedical,
  FaUser,
  FaCapsules,
} from 'react-icons/fa';
import { IoMdCash, IoIosCard as Payment } from 'react-icons/io';
import { MdConfirmationNumber } from 'react-icons/md';
import { toggleSidebar } from '../../actions';
import StyledTappable from '../common/StyledTappable';
import { withRouter } from 'react-router-dom';
import ReavampOfflineConsultations from '../revamp-offline-ops/ReavampOfflineConsultations';

import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  CardFooter,
} from 'reactstrap';
import { RegularText } from '../common/Text';

import { getMyTicketLink } from '../../services/api/tickets';

const OuterComponent = styled(ListGroup)`
  padding-top: 40px;
  display: flex;
  flex: 1;
  position: sticky;
`;

const TitleText = styled(ListGroupItemHeading)`
  background-color: #8852cc;
  color: white;
  text-align: center;
  padding: 1rem 0.25rem;
  margin: 0rem;
  font-size: 1.3rem;
  align-items: stretch;
  align-self: stretch;
`;

const BodyContainer = styled(ListGroup)`
  display: flex;
  flex: 1;
  padding: 1rem 0rem;
  background-color: #525963;
  overflow-y: scroll;
`;

const LinkContainer = styled(ListGroupItem)`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0.25rem 0.825rem;
  color: white;
  margin: 0.25rem 0.5rem;
  width: 15rem;
  box-shadow: 0.5px 0.6px 0.6px 0.5px;
  margin-bottom: 3.5px;
  font-size: 14px;
`;
const FooterContainer = styled(CardFooter)`
  background-color: #525963;
  align-items: right;
`;

const ListObject = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0.25rem 0.5rem;
  background-color: rgba(135, 135, 135, 0.9);
  margin: 0.25rem 0.1rem 0.15rem 0.5rem;
  border-radius: 0.25rem;
`;

class SideBar extends Component {
  state = {
    showSupport: false,
    showOps: false,
    showAddedDoctorRequestList: false,
    showPayment: false,
    showLabs: false,
    showAdminActions: false,
    showSponsorManagement: false,
    showCashlessVaccination: false,
    canAddDocForPayout: false,
    showReimbursementAdmin: false,
    showReimbursement: false,
    showQcTab: false,
    showCashlessDental: false,
    showCashlessVision: true,
    showScanPayClaims:false,
  };
  toggleSideBar = () => {
    this.props.toggleSideBar();
  };

  componentDidMount = () => {
    const user = this.props.user;
    if (user.showSupport) {
      this.setState({
        showSupport: true,
      });
    }
    if (user.showOps) {
      this.setState({
        showOps: true,
      });
    }
    if (user.showPayments) {
      this.setState({
        showPayment: true,
      });
    }
    if (user.showLabs) {
      this.setState({
        showLabs: true,
      });
    }
    if (user.showAdminActions) {
      this.setState({
        showAdminActions: true,
      });
    }
    if (user.showOnlinePayments) {
      this.setState({
        showOnlinePayments: true,
      });
    }
    if (user.showClaimsManagement) {
      this.setState({
        showClaimsManagement: true,
      });
    }
    if (user.showSponsorManagement) {
      this.setState({
        showSponsorManagement: true,
      });
    }

    if (user.canMakeCreds) {
      this.setState({
        canMakeCreds: true,
      });
    }
    // if (user.showReimbursementAdmin) {
    //   this.setState({
    //     showReimbursementAdmin: true,
    //   });
    // }
    // if (user.showReimbursement) {
    //   this.setState({
    //     showReimbursement: true,
    //   });
    // }
    if (user.canAddDocForPayout) {
      this.setState({
        canAddDocForPayout: true,
      });
    }
    if (user.showQcTab) {
      this.setState({
        showQcTab: true,
      });
    }
    if (user.showCashlessDental) {
      this.setState({
        showCashlessDental: true,
      });
    }
    if (user.showAddedDoctorRequestList) {
      this.setState({
        showAddedDoctorRequestList: true,
      });
    }
    if(user.showCashlessVaccination) {
      this.setState({
        showCashlessVaccination: true,
      });
    }
    if(user.showScanPayClaims) {
      this.setState({
        showScanPayClaims: true,
      });
    }

  };

  render() {
    const { history } = this.props;
    return (
      <OuterComponent>
        <BodyContainer>
          {/* <a
            href={getMyTicketLink()}
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            <StyledTappable>
              <LinkContainer className="bg-secondary">
                <IoMdCash />
                &nbsp; My Tickets
              </LinkContainer>
            </StyledTappable>
          </a>
          {this.state.showOps && (
          */}
          {this.state.showReimbursement && false && (
            <StyledTappable onTap={() => history.push('/reimburse-claims')}>
              <LinkContainer className="bg-secondary">
                <IoMdCash />
                &nbsp; Reimbursement Claims
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showOps && (
            <StyledTappable
              onTap={() => history.push('/revamp-offline-consults')}
            >
              <LinkContainer className="bg-secondary">
                <FaGlobe />
                &nbsp; Revamp Offline Consultations
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showOps && (
            <StyledTappable
              onTap={() => history.push('/agent-activity-check')}
            >
              <LinkContainer className="bg-secondary">
                <FaGlobe />
                &nbsp; OPS Team Activity
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showOps && (
            <StyledTappable
              onTap={() => history.push('/conceirge-requests')}
            >
              <LinkContainer className="bg-secondary">
                <FaGlobe />
                &nbsp; Conceirge Requests
              </LinkContainer>
            </StyledTappable>
          )}
          {/* {this.state.showOps && (
            <StyledTappable
              onTap={() => history.push('/prebooked-appointments')}
            >
              <LinkContainer className="bg-secondary">
                <FaBuffer />
                &nbsp; Prebooked Appointments
              </LinkContainer>
            </StyledTappable>
          )} */}
          {this.state.showOps && (
            <StyledTappable onTap={() => history.push('/provider-claims-settlement')}>
              <LinkContainer className="bg-secondary">
                <FaBuffer />
                &nbsp; Provider Claims Settlement
              </LinkContainer>
            </StyledTappable>
          )}
          {/* {this.state.showOps && (
            <StyledTappable
              onTap={() => history.push('/consult-reimburse-requests')}
            >
              <LinkContainer className="bg-secondary">
                <MdConfirmationNumber />
                &nbsp; Reimbursement Consultation Requests
              </LinkContainer>
            </StyledTappable>
          )} */}
          {this.state.showOps && (
            <StyledTappable
              onTap={() => history.push('/consult-reimburse-requests')}
            >
              <LinkContainer className="bg-secondary">
                <MdConfirmationNumber />
                &nbsp; Reimbursement Consultation Requests
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showOps && (
            <StyledTappable onTap={() => history.push('/doc-on-call')}>
              <LinkContainer className="bg-secondary">
                <FaGlobe />
                &nbsp; Doctor On Call
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showPayment && (
            <StyledTappable onTap={() => history.push('/payment-requests')}>
              <LinkContainer className="bg-secondary">
                <Payment />
                &nbsp; Payments Requests
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showOps && (
            <StyledTappable onTap={() => history.push('/specialist-consults')}>
              <LinkContainer className="bg-secondary">
                <FaBriefcaseMedical />
                &nbsp; Specialist Consultations
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showOps && (
            <StyledTappable onTap={() => history.push('/offline-sp')}>
              <LinkContainer className="bg-secondary">
                <FaBriefcaseMedical />
                &nbsp; Online Offline SP
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showOps && (
            <StyledTappable onTap={() => history.push('/scheduled-consults')}>
              <LinkContainer className="bg-secondary">
                <FaBriefcaseMedical />
                &nbsp; Scheduled Specialist Consults
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showSupport && (
            <StyledTappable onTap={() => history.push('/customer-support')}>
              <LinkContainer className="bg-secondary">
                <FaBuffer />
                &nbsp; Customer Support
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showLabs && (
            <StyledTappable onTap={() => history.push('/labs-orders')}>
              <LinkContainer className="bg-secondary">
                <FaBriefcaseMedical />
                &nbsp; Labs Orders
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showLabs && (
            <StyledTappable onTap={() => history.push('/ahc-requests')}>
              <LinkContainer className="bg-secondary">
                <FaBriefcaseMedical />
                &nbsp; AHC Requests
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showOnlinePayments && (
            <StyledTappable onTap={() => history.push('/online-payment')}>
              <LinkContainer className="bg-secondary">
                <FaBriefcaseMedical />
                &nbsp; Online Payments
              </LinkContainer>
            </StyledTappable>
          )}
          {/*{this.state.showLabs && (*/}
          {/*  <StyledTappable onTap={() => history.push('/labs-requests')}>*/}
          {/*    <LinkContainer className="bg-secondary">*/}
          {/*      <FaBriefcaseMedical />*/}
          {/*      &nbsp; Labs Requests*/}
          {/*    </LinkContainer>*/}
          {/*  </StyledTappable>*/}
          {/*)}*/}

          {this.state.showLabs && (
            <StyledTappable onTap={() => history.push('/labs-requests')}>
              <LinkContainer className="bg-secondary">
                <FaBriefcaseMedical />
                &nbsp; Labs Requests
              </LinkContainer>
            </StyledTappable>
          )}

          {/* {this.state.showLabs && (
            <StyledTappable onTap={() => history.push('/lab-partner-details')}>
              <LinkContainer className="bg-secondary">
                <FaBriefcaseMedical />
                &nbsp; Labs Partners
              </LinkContainer>
            </StyledTappable>
          )} */}

          {this.state.showClaimsManagement && (
            <StyledTappable>
              <LinkContainer className="bg-secondary">
                <FaUser />
                &nbsp; Claims
                <br />
                <StyledTappable
                  onTap={() =>
                    history.push('/labs-reconcile/upload-partner-tnx')
                  }
                >
                  <ListObject>
                    <RegularText>Upload Partner Tnx</RegularText>
                  </ListObject>
                </StyledTappable>
              </LinkContainer>
            </StyledTappable>
          )}

          {this.state.showAdminActions && (
            <StyledTappable>
              <LinkContainer className="bg-secondary">
                <FaUser />
                &nbsp; Admin
                <br />
                <div className="d-flex flex-column">
                  <StyledTappable onTap={() => history.push('/doctor-pool')}>
                    <ListObject>
                      <RegularText>Doctor Pool</RegularText>
                    </ListObject>
                  </StyledTappable>
                  <StyledTappable onTap={() => history.push('/sponsor-pool')}>
                    <ListObject>
                      <RegularText>Sponsor wise pool</RegularText>
                    </ListObject>
                  </StyledTappable>
                  <StyledTappable
                    onTap={() => history.push('/doctor-unattended')}
                  >
                    <ListObject>
                      <RegularText>Doctor live stats</RegularText>
                    </ListObject>
                  </StyledTappable>
                </div>
              </LinkContainer>
            </StyledTappable>
          )}
          {/* Disable OPD Management tab for now */}
          {/* <StyledTappable onTap={() => history.push('/opd-management')}>
            <LinkContainer className="bg-secondary">
              <FaBuffer />
              &nbsp; OPD Management
            </LinkContainer>
          </StyledTappable> */}
          {this.props.user.showRedemption === 1 ? (
            <StyledTappable>
              <LinkContainer className="bg-secondary">
                <FaBuffer />
                &nbsp; Redemptions
                <br />
                <StyledTappable onTap={() => history.push('/products')}>
                  <ListObject>
                    <RegularText>Products</RegularText>
                  </ListObject>
                </StyledTappable>
                <br />
                <StyledTappable onTap={() => history.push('/pools')}>
                  <ListObject>
                    <RegularText>Pools</RegularText>
                  </ListObject>
                </StyledTappable>
              </LinkContainer>
            </StyledTappable>
          ) : (
            ''
          )}
          {this.state.showSponsorManagement && (
            <StyledTappable onTap={() => history.push('/sponsor-management')}>
              <LinkContainer className="bg-secondary">
                <FaBuffer />
                &nbsp; Sponsor Management
              </LinkContainer>
            </StyledTappable>
          )}
          {this.props.user.showRedemption === 1 ? (
            <StyledTappable onTap={() => history.push('/redemption-ops')}>
              <LinkContainer className="bg-secondary">
                <FaBuffer />
                &nbsp; Redemption OPS
              </LinkContainer>
            </StyledTappable>
          ) : (
            ''
          )}
          {this.state.showLabs && (
            <StyledTappable onTap={() => history.push('/pharmacy-orders')}>
              <LinkContainer className="bg-secondary">
                <FaCapsules />
                &nbsp; Pharmacy Orders
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showLabs && (
            <StyledTappable onTap={() => history.push('/pharmacy-scan-and-pay')}>
              <LinkContainer className="bg-secondary">
                <FaCapsules />
                &nbsp; Pharmacy Scan And Pay
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showLabs && (
            <StyledTappable
              onTap={() => history.push('/online-consultation-booking')}
            >
              <LinkContainer className="bg-secondary">
                <FaBuffer />
                &nbsp; Online Consultation Booking
              </LinkContainer>
            </StyledTappable>
          )}
          {this.props.user.showExceptionalReimbursement > 0 && (
            <StyledTappable
              onTap={() => history.push('/exceptional-reimbursement')}
            >
              <LinkContainer className="bg-secondary">
                <FaBuffer />
                &nbsp; Exceptional Reimbursment
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showOps && (
            <StyledTappable
              onTap={() => history.push('/physiotherapy-program-enrolls')}
            >
              <LinkContainer className="bg-secondary">
                <FaGlobe />
                &nbsp; Physiotherapy Program Enrolls
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showOps && (
            <StyledTappable onTap={() => history.push('/intimation-requests')}>
              <LinkContainer className="bg-secondary">
                <FaGlobe />
                &nbsp; Intimation Requests
              </LinkContainer>
            </StyledTappable>
          )}

          {this.state.showCashlessVaccination && (
            <StyledTappable onTap={() => history.push('/cashless-vaccination')}>
              <LinkContainer className="bg-secondary">
                <FaGlobe />
                &nbsp; Cashless Vaccination
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showCashlessDental && (
            <StyledTappable onTap={() => history.push('/cashless-dental')}>
              <LinkContainer className="bg-secondary">
                <FaGlobe />
                &nbsp; Cashless Dental
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showCashlessVision && (
            <StyledTappable onTap={() => history.push('/cashless-vision')}>
              <LinkContainer className="bg-secondary">
                <FaGlobe />
                &nbsp; Cashless Vision
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showOps && (
            <StyledTappable onTap={() => history.push('/coupons')}>
              <LinkContainer className="bg-secondary">
                <FaGlobe />
                &nbsp; Coupon Codes
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showOps && (
            <StyledTappable
              onTap={() => history.push('/added-doctor-request-list')}
            >
              <LinkContainer className="bg-secondary">
                <FaGlobe />
                &nbsp; Added Doctor Request List
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.canMakeCreds && (
            <StyledTappable onTap={() => history.push('/make-credentials')}>
              <LinkContainer className="bg-secondary">
                <FaGlobe />
                &nbsp; Make Credentials
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.canAddDocForPayout && (
            <StyledTappable onTap={() => history.push('/addDoctors')}>
              <LinkContainer className="bg-secondary">
                <FaUser />
                &nbsp; Add Payout Doctor
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showReimbursementAdmin && false(
            <StyledTappable onTap={() => history.push('/reimbursement-admin')}>
              <LinkContainer className="bg-secondary">
                <FaUser />
                &nbsp;Reimbursement Admin
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showQcTab && (
            <StyledTappable onTap={() => history.push('/qc-reimbursement')}>
              <LinkContainer className="bg-secondary">
                <FaUser />
                &nbsp; QC Tab
              </LinkContainer>
            </StyledTappable>
          )}
          {this.state.showReimbursement && false && (
            <StyledTappable onTap={() => history.push('/qc-remark')}>
              <LinkContainer className="bg-secondary">
                <FaUser />
                &nbsp; QC Remark
              </LinkContainer>
            </StyledTappable>
          )}
          <LinkContainer className="bg-secondary">
            &nbsp; Version 2.4
          </LinkContainer>
        </BodyContainer>
        <FooterContainer className="app-footer">
          <StyledTappable onTap={() => this.toggleSideBar()}>
            <FaChevronLeft />
          </StyledTappable>
        </FooterContainer>
      </OuterComponent>
    );
  }
}

// const SideBarWithRouter = withRouter(SideBar);
const mapDispatchToProps = (dispatch) => ({
  toggleSideBar: () => {
    dispatch(toggleSidebar());
  },
});

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SideBar));

import React, { useState } from 'react';
import { Button } from 'reactstrap';
import FileSaver from 'file-saver';
import jsonToCsv from '../../utils/jsonToCsv';
import { getAllOfflineRequests } from '../../services/api/offline-consult';

export default class DownloadCsv extends React.Component {
  state = {
    isLoading: false,
    dots: '',
  };

  componentDidUpdate(_, prevState) {
    if (this.state.isLoading && !prevState.isLoading) {
      this.startLoadingAnimation();
    }
  }

  startLoadingAnimation = () => {
    let count = 0;
    this.loadingInterval = setInterval(() => {
      const dots = '.'.repeat((count % 3) + 1); // Cycles between ., .., ...
      this.setState({ dots });
      count++;
    }, 400);
  };

  stopLoadingAnimation = () => {
    clearInterval(this.loadingInterval);
    this.setState({ isLoading: false, dots: '' });
  };

  handleClick = () => {
    
    this.setState({ isLoading: true });
    
    const downloadCsv = (jsonArray, fileName) => {
      const csv = jsonToCsv(jsonArray);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      FileSaver.saveAs(blob, fileName);
      this.stopLoadingAnimation();
    };

    if (this.props.isCashless) {
      this.props.callback().then((response) => {
        downloadCsv(response.result, this.props.fileName || 'cashless_vaccination.csv');
      });
    } else if (this.props.isCashlessDental) {
      this.props.callback().then((response) => {
        console.log({ response }, '[callback]');
        downloadCsv(response.data.rows, this.props.fileName || 'cashless_dental.csv');
      });
    } else if (this.props.jsonArray) {
      downloadCsv(this.props.jsonArray, this.props.fileName || 'exports.csv');
    } else {
      const params = { ...this.props.params, draw: 100000 };
      getAllOfflineRequests(params).then((jsonArray) => {
        if (jsonArray) {
          downloadCsv(jsonArray.result, 'offline_request.csv');
        } else {
          this.stopLoadingAnimation();
        }
      });
    }
  };

  render() {
    return (
      <button
        style={{
          background: '#714FFF',
          borderColor: '#714FFF',
          margin: '0px',
          borderRadius: '5px',
          border: '0px',
          color: 'white',
          padding: '12px 12px',
          fontSize: '14px',
          fontWeight: '600',
          fontFamily: 'Inter, sans-serif',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center', // Ensures text is centered
          minWidth: '150px',
          width: 'auto',
        }}
        onClick={this.handleClick}
        disabled={this.state.isLoading}
      >
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {this.state.isLoading ? `Downloading` : `Download CSV`}<span style={{ minWidth: '0px' }}>{this.state.isLoading ? this.state.dots : ''}</span>
        </span>
      </button>
    );
  }
}
import axios from 'axios';
import config from '../../config';

const redirectForLogin = () => {
  window.location.href = '/';
 }
 
 axios.interceptors.response.use((response) => response,  (error) => {
   const statusCode = error.response.data.code;
   console.log(statusCode);
   return statusCode === 401 ?  redirectForLogin() : Promise.reject(error);
 });

export function uploadImageAttachment(
  file,
  doctorId,
  consultationId,
  userId,
  requestId,
  onUploadProgress,
  authToken,
  requestedByType = 'user'
) {
  const headers = {
    authorization: authToken,
  };

  const formData = new FormData();
  formData.append('file', file);
  formData.append('consultationId', consultationId);
  formData.append('doctorId', doctorId);
  formData.append('userId', userId);
  formData.append('requestId', requestId);
  formData.append('preview', file);
  formData.append('prescription', 1);
  formData.append('requestedByType', requestedByType);
  return axios
    .post(`${config.baseUrl}/ops/users/${userId}/upload`, formData, {
      headers,
      onUploadProgress,
    })
    .then(resp => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch(err => {
      throw err;
    });
}

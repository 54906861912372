import React, { useState, useEffect } from 'react';
import { Button } from 'antd';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import hidePasswordIcon from '../../assets/img/brand/hidePassword.png';
import showPasswordIcon from '../../assets/img/brand/showPassword.png';
import {
  approveCredentials,
  reasonCredentials,
  updateCredentials,
} from '../../services/api/makeCredential';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import './EditCredentialModal.css';
import { exceptionalReimbursementOptionLabels } from '../../constant';

const ViewModal = (props) => {
  const [name, setName] = useState();
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showOnlinePayments, setShowOnlinePayments] = useState();
  const [showLabs, setShowLabs] = useState();
  const [showPaymentRequest, setShowPaymentRequest] = useState();
  const [showSupport, setShowSupport] = useState();
  const [showSponsorManagement, setShowSponsorManagement] = useState();
  const [poolAdministration, setPoolAdministration] = useState();
  const [showRedemption, setShowRedemption] = useState();
  const [approvalRequestId, setApprovalRequestId] = useState();
  const [opsUserId, setOpsUserId] = useState();
  const [requestedBy, setRequestedBy] = useState();
  const [showAdmin, setShowAdmin] = useState();
  const [showExceptionalReimbursement, setShowExceptionalReimbursement] =
    useState();
  const [showClaims, setShowClaims] = useState();
  const [showReimbursement, setShowReimbursement] = useState();
  const [showReimbursementAdmin, setShowReimbursementAdmin] = useState();
  const [showCashlessDental, setShowCashlessDental] = useState();
  const [showCashlessVaccination, setShowCashlessVaccination] = useState();
  const [showScanPayClaims, setShowScanPayClaims] = useState();
  const [isrejectButtonClicked, setIsRejectButtonClicked] = useState(false);
  const [id, setId] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [inputValue, setInputValue] = useState('');
  const [status, setStatus] = useState();
  const history = useHistory();
  const [isInputEmpty, setIsInputEmpty] = useState(false);

  const { isModalOpen, closeViewModal, selectedRowData = {} } = props;
  console.log(props.rowData._original, 'selectedRowData');

  useEffect(() => {
    setName(selectedRowData.name);
    setUserName(selectedRowData.userName);
    setShowOnlinePayments(selectedRowData.showOnlinePayments);
    setShowLabs(selectedRowData.showLabs);
    setShowPaymentRequest(selectedRowData.showPayments);
    setShowSupport(selectedRowData.showSupport);
    setShowSponsorManagement(selectedRowData.showSponsorManagement);
    setPoolAdministration(selectedRowData.poolAdministration);
    setShowRedemption(selectedRowData.showRedemption);
    setShowExceptionalReimbursement(
      selectedRowData.showExceptionalReimbursement
    );
    setShowClaims(selectedRowData.showClaimsMgr);
    setShowReimbursement(selectedRowData.showReimbursement);
    setShowReimbursementAdmin(selectedRowData.reimbursementAdmin);
    setShowCashlessDental(selectedRowData.showCashlessDental);
    setShowCashlessVaccination(selectedRowData.showCashlessVaccination);
    setShowScanPayClaims(selectedRowData.showScanPayClaims);
    setId(selectedRowData.id);
    setPhone(selectedRowData.phone);
    setEmail(selectedRowData.email);
  }, [selectedRowData]);

  const closeBtn = (
    <button className="close" onClick={closeViewModal} type="button">
      &times;
    </button>
  );

  const handleClick = () => {
    setIsRejectButtonClicked(true);
  };
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const approveCreds = async () => {
    const {
      approvalRequestId,
      opsUserId,
      name,
      userName,
      password,
      showOnlinePayments,
      showLabs,
      showPayments,
      showSupport,
      showSponsorManagement,
      poolAdministration,
      showRedemption,
      showReimbursement,
      reimbursementAdmin,
      showExceptionalReimbursement,
      showClaimsMgr,
      showAdmin,
      status,
      requestedByUserName,
      requestedByUserId,
      showCashlessDental,
      showCashlessVaccination,
      showScanPayClaims,
    } = props.rowData._original;
    const data = {
      approvalRequestId,
      opsUserId,
      requestedBy: requestedByUserId,
      name,
      userName,
      password,
      showOnlinePayments,
      showLabs,
      showPayments,
      showSupport,
      showSponsorManagement,
      poolAdministration,
      showRedemption,
      showReimbursement,
      reimbursementAdmin,
      showExceptionalReimbursement,
      showClaimsMgr,
      showAdmin,
      status,
      requestedByUserName,
      showCashlessDental,
      showCashlessVaccination,
      showScanPayClaims,
    };
    try {
      const approvedData = await approveCredentials(data);
      console.log(approvedData, 'updated');
      if (approvedData.message === 'Success') {
        toast.success('Credential updated successfully');
        props.getOpsCredApprovals();
      } else {
        const err = new Error('Invalid response');
        err.data = approvedData.err;
        throw err;
      }
    } catch (err) {
      console.log({ err, response: err.data });
      toast.error('User cannot approve his own created request');
    } finally {
      closeViewModal();
      history.push('/approve-credentials');
    }
  };

  const rejectCreds = async () => {
    const { approvalRequestId, requestedByUserId } = props.rowData._original;
    const data = {
      approvalRequestId,
      requestedBy: requestedByUserId,
      comment: inputValue,
    };
    try {
      const rejectedData = await reasonCredentials(data);
      console.log(rejectedData, 'rejected');
      if (rejectedData.message === 'Success') {
        toast.success('Credential rejected successfully');
        props.getOpsCredApprovals();
      } else {
        const err = new Error('Invalid response');
        err.data = rejectedData.err;
        throw err;
      }
    } catch (err) {
      console.log({ err, response: err.data });
      toast.error('User cannot reject his own created request');
    } finally {
      closeViewModal();
      history.push('/approve-credentials');
    }
  };

  return (
    <Modal isOpen={isModalOpen} toggle={closeViewModal}>
      <ModalHeader closeViewModal={closeViewModal} close={closeBtn}>
        Update Credential
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" for="name" md={6}>
              Name:
            </Label>
            <Col>{props.rowData._original.name}</Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" for="userName" md={6}>
              User Name
            </Label>
            <Col md={6}>{props.rowData._original.userName}</Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="approvalRequestId">
              Phone
            </Label>
            <Col md={6}>{props.rowData._original.phone}</Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="approvalRequestId">
              Email
            </Label>
            <Col md={6}>{props.rowData._original.email}</Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" for="password" md={6}>
              New Password
            </Label>
            <Col md={6} style={{ position: 'relative' }}>
              {props.rowData._original.password}
            </Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="approvalRequestId">
              Requested By
            </Label>
            <Col md={6}>{props.rowData._original.requestedByUserName}</Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="showOnlinePayments">
              Show Online Payments
            </Label>
            <Col md={6}>{props.rowData._original.showOnlinePayments}</Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="showLabs">
              Show Labs
            </Label>
            <Col md={6}>{props.rowData._original.showLabs}</Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="showPaymentRequest">
              Show Payment Request
            </Label>
            <Col md={6}>{props.rowData._original.showPayments}</Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="showSupport">
              Show Support
            </Label>
            <Col md={6}>{props.rowData._original.showSupport}</Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label
              className="edit-modal-label"
              md={6}
              for="showSponsorManagement"
            >
              Show Sponsor Management
            </Label>
            <Col md={6}>{props.rowData._original.showSponsorManagement}</Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="poolAdministration">
              Pool Administration
            </Label>
            <Col md={6}>{props.rowData._original.poolAdministration}</Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="showAdmin">
              Show Admin
            </Label>
            <Col md={6}>{props.rowData._original.showAdmin}</Col>
          </FormGroup>
          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="showRedemption">
              Show Redemption
            </Label>
            <Col md={6}>{props.rowData._original.showRedemption}</Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label
              className="edit-modal-label"
              md={6}
              for="showExceptionalReimbursement"
            >
              Show Exceptional Reimbursement
            </Label>
            <Col md={6}>
              {String(props.rowData._original.showExceptionalReimbursement)
                .split('')
                .map((val) =>
                  exceptionalReimbursementOptionLabels.find(option => option.value === val).label
                )
                .join(', ') || 'None selected'}
            </Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="showClaims">
              Show Claims
            </Label>
            <Col md={6}>{props.rowData._original.showClaimsMgr}</Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="showReimbursement">
              Show Reimbursement Claim
            </Label>
            <Col md={6}>{props.rowData._original.showReimbursement}</Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label
              className="edit-modal-label"
              md={6}
              for="showReimbursementAdmin"
            >
              Show Reimbursement Admin
            </Label>
            <Col md={6}>{props.rowData._original.reimbursementAdmin}</Col>
          </FormGroup>
          <FormGroup className="edit-row-modal" row>
            <Label
              className="edit-modal-label"
              md={6}
              for="showCashlessDental"
            >
              Show Cashless Dental
            </Label>
            <Col md={6}>{props.rowData._original.showCashlessDental}</Col>
          </FormGroup>
          <FormGroup className="edit-row-modal" row>
            <Label
              className="edit-modal-label"
              md={6}
              for="showCashlessVaccination"
            >
              Show Cashless Vaccination
            </Label>
            <Col md={6}>{props.rowData._original.showCashlessVaccination}</Col>
          </FormGroup>
          <FormGroup className="edit-row-modal" row>
            <Label
              className="edit-modal-label"
              md={6}
              for="showScanPayClaims"
            >
              Show Scan and Pay Claims
            </Label>
            <Col md={6}>{props.rowData._original.showScanPayClaims}</Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        {/* <Button color="secondary" onClick={closeViewModal}>
          Discard
        </Button> */}

        <Button
          disabled={
            props.rowData._original.status === 'Approved' ||
            props.rowData._original.status === 'Rejected'
          }
          color="primary"
          onClick={() => approveCreds()}
        >
          Approve
        </Button>
        <div
          style={{
            dispaly: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'space-evenly',
          }}
        >
          {console.log(props.rowData._original.status, 'dsdsadsa')}
          <Button
            disabled={
              props.rowData._original.status === 'Approved' ||
              props.rowData._original.status === 'Rejected'
            }
            color="secondary"
            onClick={handleClick}
          >
            Reject
          </Button>
          {isrejectButtonClicked && (
            <>
              <div
                style={{
                  position: 'relative',
                  display: 'inline-block',
                  marginLeft: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '10px',
                  padding: '3px',
                }}
              >
                <input
                  onChange={handleChange}
                  value={inputValue}
                  type="text"
                  style={{
                    border: 'none',
                    color: 'black',
                  }}
                  placeholder="Reason for rejection"
                />
                <img src="SubmitButton" />
                <Button
                  style={{
                    position: 'absolute',
                    right: '0',
                    top: '0',

                    backgroundColor: '#4CAF50', // Change as needed
                    color: 'white', // Change as needed
                    border: 'none', // Change as needed
                    cursor: 'pointer', // Change as needed
                    borderRadius: '7px',
                    marginRight: '0px',
                  }}
                  disabled={!inputValue || inputValue === '0'}
                  onClick={rejectCreds}
                >
                  Submit
                </Button>
              </div>
            </>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ViewModal;

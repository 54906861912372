import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { UncontrolledTooltip, Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Input } from 'reactstrap';
import TickButton from '../../images/TickButton.svg';
import CrossButton from '../../images/CrossButton.svg';
import PenButton from '../../images/PenButton.svg';
import CompleteButton from '../../images/CompleteButton.svg';
import searchIcon from '../../images/SearchIcon.svg';
import RequestPaymentButton from '../../images/requestPayment.svg';
import Select from 'react-select';
import config from '../../config';
import { approveVisionOrder, cancelVisionOrder, markVisionOrderCompleted, requestPaymentFromUser, updateVisionRequest } from '../../services/api/cashless-vision';
import OrderDetailsModal from './orderDetailsModal';

const buttonStyle = {
  backgroundColor: '#714FFF',
  border: 'none',
  width: '112px',
  height: '32px',
  fontSize: '14px',
  fontWeight: '600',
  color: '#FFFFFF',
  alignContent: 'center'
}

const buttonStyle2 = {
  backgroundColor: '#FFFFFF',
  border: '1px solid #714FFF',
  width: '112px',
  height: '32px',
  fontSize: '14px',
  fontWeight: '600',
  color: '#714FFF',
  alignContent: 'center'
}

const VisionActions = ({
  requestId,
  getVisionRequestData,
  requestData
}) => {
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRequestPaymentModal, setShowRequestPaymentModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showOrderCompleteModal, setShowOrderCompleteModal] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [otherRejectReason, setOtherRejectReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const rejectReasons = [
    'Vision Card not uploaded',
    'Payment not completed',
    'Vision card not verified',
    'Other'
  ];

  const approveOrder = () => {
    setShowApproveModal(true);
  };

  const requestPayment = () => {
    setShowRequestPaymentModal(true);
  };

  const toggleEditOrderModal = () => {
    setShowEditModal(!showEditModal);
  };
  
  const handleEditOrderDetails = async (requestId, orderDetails) => {
    try {
      const response = await updateVisionRequest(requestId, orderDetails);

      if (response.message === 'success') {
        setShowEditModal(false);
        getVisionRequestData();
      }
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleApproveConfirm = async () => {
    try {
      setLoading(true);
      await approveVisionOrder(requestId);
      toast.success('Order approved successfully');
      setShowApproveModal(false);
      getVisionRequestData();
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to approve order');
    } finally {
      setLoading(false);
    }
  };

  const handleRequestPayment = async () => {
    try {
      setLoading(true);
      await requestPaymentFromUser(requestId);
      toast.success('Payment requested successfully');
      setShowRequestPaymentModal(false);
      getVisionRequestData();
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to request payment for this order');
    } finally {
      setLoading(false);
    }
  };

  const rejectOrder = () => {
    setShowRejectModal(true);
  };

  const handleRejectConfirm = async () => {
    if (!rejectReason || (rejectReason === 'Other' && !otherRejectReason)) {
      toast.error('Please select a reason for rejection');
      return;
    }

    try {
      setLoading(true);
      const finalReason = rejectReason === 'Other' ? otherRejectReason : rejectReason;
      await cancelVisionOrder(requestId, finalReason);
      toast.success('Order rejected successfully');
      setShowRejectModal(false);
      setRejectReason('');
      setOtherRejectReason('');
      getVisionRequestData();
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to reject order');
    } finally {
      setLoading(false);
    }
  };

  const editOrder = () => {
    setShowEditModal(true);
  };

  const markOrderCompleted = () => {
    setShowOrderCompleteModal(true);
  };

  const handleMarkOrderCompleted = async () => {
    try {
      setLoading(true);
      await markVisionOrderCompleted(requestId);
      toast.success('Order completed marked successfully');
      setShowOrderCompleteModal(false);
      getVisionRequestData();
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to mark order completed');
    } finally {
      setLoading(false);
    }
  };
  

  const redirectToKea = (journeyType) => {
    window.open(`${config.keaAppUrl}?requestId=${requestData.requestId}&journey=${journeyType}`, '_blank');
  };

  return !requestData.isOfflineStorePartner ? (
      <>
        <div className="d-flex flex-row justify-center" style={{ gap: '8px' }}>
          <UncontrolledTooltip target={`approveButton-${requestId}`}>
          Approve Order
        </UncontrolledTooltip>
        <img
          src={TickButton}
          alt="Approve"
          className="button-img"
          style={{ width: '24px', height: '24px', cursor: 'pointer' }}
          id={`approveButton-${requestId}`}
          onClick={approveOrder}
        />

        <UncontrolledTooltip target={`rejectButton-${requestId}`}>
          Reject Order
        </UncontrolledTooltip>
        <img
          id={`rejectButton-${requestId}`}
          onClick={rejectOrder}
          src={CrossButton}
          alt="Reject"
          className="button-img"
          style={{ width: '24px', height: '24px', cursor: 'pointer' }}
        />

        <UncontrolledTooltip target={`requestPaymentButton-${requestId}`}>
          Request Payment
        </UncontrolledTooltip>
        <img
          src={RequestPaymentButton}
          alt="Request Payment"
          className="button-img"
          id={`requestPaymentButton-${requestId}`}
          onClick={requestPayment}
          style={{ width: '24px', height: '24px', cursor: 'pointer' }}
        />

        <UncontrolledTooltip target={`editButton-${requestId}`}>
          Edit Order
        </UncontrolledTooltip>
        <img
          src={PenButton}
          alt="Edit"
          className="button-img"
          id={`editButton-${requestId}`}
          onClick={editOrder}
          style={{ width: '24px', height: '24px', cursor: 'pointer' }}
        />

        <UncontrolledTooltip target={`completeButton-${requestId}`}>
          Mark Order Completed
        </UncontrolledTooltip>
        <img
          src={CompleteButton}
          alt="Mark Order Completed"
          className="button-img"
          id={`completeButton-${requestId}`}
          onClick={markOrderCompleted}
          style={{ width: '24px', height: '24px', cursor: 'pointer' }}
        />

      </div>

      {/* Approve Confirmation Modal */}
      <Modal isOpen={showApproveModal} toggle={() => setShowApproveModal(false)} centered>
        <ModalHeader toggle={() => setShowApproveModal(false)}>
          Confirm Approval
        </ModalHeader>
        <ModalBody>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            Are you sure you want to approve this order?
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <Button
              onClick={handleApproveConfirm}
              disabled={loading}
              style={{
                backgroundColor: '#714FFF',
                border: 'none',
                width: '100px'
              }}
            >
              {loading ? 'Processing...' : 'Yes'}
            </Button>
            <Button
              onClick={() => setShowApproveModal(false)}
              style={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #714FFF',
                color: '#714FFF',
                width: '100px'
              }}
            >
              No
            </Button>
          </div>
        </ModalBody>
      </Modal>

      {/* Reject Confirmation Modal */}
      <Modal isOpen={showRejectModal} toggle={() => setShowRejectModal(false)} centered>
        <ModalHeader toggle={() => setShowRejectModal(false)}>
          Reject Order
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Select Reason for Rejection</Label>
            <Select
              options={rejectReasons.map(reason => ({ value: reason, label: reason }))}
              value={{ value: rejectReason, label: rejectReason }}
              onChange={(selected) => setRejectReason(selected.value)}
              placeholder="Select a reason"
            />
            {rejectReason === 'Other' && (
              <Input
                type="textarea"
                value={otherRejectReason}
                onChange={(e) => setOtherRejectReason(e.target.value)}
                placeholder="Please specify the reason"
                style={{ marginTop: '10px' }}
              />
            )}
          </FormGroup>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}>
            <Button
              onClick={handleRejectConfirm}
              disabled={loading || !rejectReason || (rejectReason === 'Other' && !otherRejectReason)}
              style={{
                backgroundColor: '#714FFF',
                border: 'none',
                width: '100px'
              }}
            >
              {loading ? 'Processing...' : 'Confirm'}
            </Button>
            <Button
              onClick={() => {
                setShowRejectModal(false);
                setRejectReason('');
                setOtherRejectReason('');
              }}
              style={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #714FFF',
                color: '#714FFF',
                width: '100px'
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>

      {/* Mark Order Completed Modal */}
      <Modal isOpen={showOrderCompleteModal} toggle={() => setShowOrderCompleteModal(false)} centered>
        <ModalHeader toggle={() => setShowOrderCompleteModal(false)}>
          Mark Order Completed
        </ModalHeader>
        <ModalBody>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            Are you sure you want to mark this order completed?
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <Button
              onClick={handleMarkOrderCompleted}
              disabled={loading}
              style={{
                backgroundColor: '#714FFF',
                border: 'none',
                width: '100px'
              }}
            >
              {loading ? 'Processing...' : 'Yes'}
            </Button>
            <Button
              onClick={() => setShowOrderCompleteModal(false)}
              style={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #714FFF',
                color: '#714FFF',
                width: '100px'
              }}
            >
              No
            </Button>
          </div>
        </ModalBody>
      </Modal>

      {/* Edit Vision Request Modal */}
      {showEditModal && (
        <OrderDetailsModal
          isOpen={showEditModal}
          toggle={toggleEditOrderModal}
          onSubmit={handleEditOrderDetails}
          requestId={requestId}
          ModalHeader={'Edit Order Details'}
        />
      )}

      {/* Collect Payment Modal */}
      <Modal isOpen={showRequestPaymentModal} toggle={() => setShowRequestPaymentModal(false)} centered>
        <ModalHeader toggle={() => setShowRequestPaymentModal(false)}>
          Request Payment
        </ModalHeader>
        <ModalBody>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            Are you sure you want to request payment for this order?
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <Button
              onClick={handleRequestPayment}
              disabled={loading}
              style={{
                backgroundColor: '#714FFF',
                border: 'none',
                width: '100px'
              }}
            >
              {loading ? 'Processing...' : 'Yes'}
            </Button>
            <Button
              onClick={() => setShowRequestPaymentModal(false)}
              style={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #714FFF',
                color: '#714FFF',
                width: '100px'
              }}
            >
              No
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </> 
  ) : (
     // Create a button to add item if requestData.invoiceUploaded
     (!requestData.invoiceUploaded || requestData.requestStatus === 'document-rejected') ? (
      <div className="d-flex flex-row justify-center" style={{ gap: '8px' }}>
        <Button onClick={() => redirectToKea('upload')} style={buttonStyle}>+ Upload</Button>
      </div>
    ) : requestData.preAuthCount === 0 ? (
      <div>
        <Button onClick={() => redirectToKea('addItem')} style={buttonStyle2}>+ Add Item</Button>
      </div>
    ) : (
      <div className="d-flex flex-row justify-center" style={{ gap: '8px' }}>
        <UncontrolledTooltip target={`completeButton-${requestId}`}>
          Mark Order Completed
        </UncontrolledTooltip>
        <img
          src={CompleteButton}
          alt="Mark Order Completed"
          className="button-img"
          id={`completeButton-${requestId}`}
          onClick={markOrderCompleted}
          style={{ width: '24px', height: '24px', cursor: 'pointer' }}
        />
        <UncontrolledTooltip target={`kiaLink-${requestId}`}>
          Kia Link
        </UncontrolledTooltip>
        <img
          src={searchIcon}
          alt="Kia Link"
          className="button-img"
          id={`kiaLink-${requestId}`}
          onClick={() => redirectToKea()}
          style={{ width: '24px', height: '24px', cursor: 'pointer' }}
        />
        </div>
    )
  )
};

export default VisionActions;

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NavBar from '../layout/NavBar';
import ReactTable from 'react-table';
import FoldableTableHOC from 'react-table/lib/hoc/foldableTable';
import { useColumns, useData } from './hooks';
import { fetchFileRequests } from './api';
import { MainButton, MainRow, OuterComponent, PaddedContainer } from './styles';
import CreateReimbursementModal from './modals/CreateReimbursementModal';
import {
  creationFilterCallback,
  customSort,
  dataFilter,
  decideFilterCallback,
  downloadCSV,
} from './utils';
import FileLinks from './modals/FileLinks';
import OfflineCashgramModal from './modals/OfflineCashgramModal';
import LabCashgramModal from './modals/LabCashgramModal';

const FoldableTable = FoldableTableHOC(ReactTable);

// number of records will never exceed this amount
const STATIC_FILE_LIMIT = 10000;
const INITIAL_DATE = { startDate: null, endDate: null };

const ExceptionalReimbursement = () => {
  // modal states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [urlModalId, setUrlModalId] = useState('');
  const [offlineCashgramId, setOfflineCashgramId] = useState(null);
  const [labCashgramId, setLabCashgramId] = useState(null);

  // global states
  const [completeApiData, setCompleteApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('all'); // enum (consultation, lab, all)
  const [totalResults, setTotalResults] = useState(0);

  // table column states
  const [currentAction, setCurrentAction] = useState('');

  // pagination states
  const [pageSize, setPageSize] = useState(25);
  const [currentData, setCurrentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [showPending, setShowPending] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [creationDate, setCreationDate] = useState(INITIAL_DATE);
  const [showUploaded, setShowUploaded] = useState('all'); // enum (all, yes, no)

  // sorting and filtering states
  const [sortOrder, setSortOrder] = useState({ id: '', desc: false });
  const [filterData, setFilterData] = useState([]);

  const { showExceptionalReimbursement } = useSelector(state => state.user);


  // callback function to fetch records
  const fetchData = useCallback(() => {
    setIsLoading(true);
    fetchFileRequests({ limit: STATIC_FILE_LIMIT })
      .then((response) => {
        setCompleteApiData(
          response.file_requests
            .filter((item) => {
              const { destination } = item;
              const destArr = (destination || '').split('/');
              const orderId = destArr[destArr.length - 1];
              if (currentTab === 'all') return true;
              return orderId[0] === currentTab[0];
            })
            .map((item) => {
              const { destination } = item;
              const destArr = (destination || '').split('/');
              return {
                ...item,
                orderId: destArr[destArr.length - 1],
                userUploaded: item.file_count > 0,
              };
            })
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, [currentTab]);

  // initially fetching all records
  useEffect(fetchData, [fetchData]);

  // memoized columns and data
  const columns = useColumns({
    setUrlModalId,
    onComplete: fetchData,
    currentAction,
    setCurrentAction,
    setCreationDate,
    setOfflineCashgramId,
    setLabCashgramId,
    showUploaded,
    setShowUploaded,
  });
  const data = useData(currentData);

  // effect that filters, sorts and paginates the data
  useEffect(() => {
    const filterCallback = decideFilterCallback(showPending, showCompleted);
    let afterFilter = dataFilter(completeApiData, filterData, filterCallback);
    if (creationDate.startDate && creationDate.endDate) {
      afterFilter = afterFilter.filter((item) =>
        creationFilterCallback(item, creationDate)
      );
    }
    if (['yes', 'no'].includes(showUploaded)) {
      afterFilter = afterFilter.filter((item) =>
        showUploaded === 'yes' ? item.is_open : !item.is_open
      );
    }
    const afterSort = sortOrder.id
      ? customSort(afterFilter, sortOrder.id, sortOrder.desc)
      : afterFilter;
    const afterSlice = afterSort.slice(
      pageSize * currentPage,
      pageSize * (currentPage + 1)
    );

    setCurrentData(afterSlice);
    setTotalResults(afterSort.length);
  }, [
    completeApiData,
    currentPage,
    showPending,
    sortOrder,
    filterData,
    showCompleted,
    pageSize,
    creationDate,
    showUploaded,
  ]);

  // function called by react-table component to set filter/sort/pagination details
  const onFetchData = (state) => {
    setCurrentPage(state.page);
    setFilterData(state.filtered);
    setPageSize(state.pageSize);
    if (Array.isArray(state.sorted) && state.sorted.length) {
      setSortOrder(state.sorted[0]);
    }
  };

  // return the jsx
  return (
    <OuterComponent>
      <NavBar />
      <PaddedContainer className="pt-4 mt-5">
        <MainRow>
          <MainButton
            onClick={() => {
              setIsModalOpen((m) => !m);
            }}
          >
            Create Reimbursement Request
          </MainButton>
          <div>
            <MainButton
              onClick={() => {
                setShowPending((p) => !p);
                setShowCompleted(false);
              }}
              style={{ marginRight: '5px', width: '125px' }}
            >
              {showPending ? 'Hide' : 'Show'} Pending
            </MainButton>
            <MainButton
              disabled={currentTab === 'lab'}
              style={{
                background: currentTab === 'lab' ? '#aaa' : '',
                marginRight: '5px',
              }}
              onClick={() => setCurrentTab('lab')}
            >
              {currentTab !== 'lab' ? 'Switch To ' : ''}Lab Orders
            </MainButton>
            <MainButton
              disabled={currentTab === 'all'}
              onClick={() => setCurrentTab('all')}
              style={{
                marginRight: '5px',
                background: currentTab === 'all' ? '#aaa' : '',
              }}
            >
              All Requests
            </MainButton>
            <MainButton
              disabled={currentTab === 'consultation'}
              style={{
                background: currentTab === 'consultation' ? '#aaa' : '',
                marginRight: '5px',
              }}
              onClick={() => setCurrentTab('consultation')}
            >
              {currentTab !== 'consultation' ? 'Switch To ' : ''}Offline
              Consultations
            </MainButton>
            <MainButton
              style={{ width: '135px', marginRight: '5px' }}
              onClick={() => {
                setShowCompleted((c) => !c);
                setShowPending(false);
              }}
            >
              {showCompleted ? 'Hide' : 'Show'} Completed
            </MainButton>
            <MainButton
              onClick={() => {
                setShowCompleted(false);
                setShowPending(false);
                setCreationDate(INITIAL_DATE);
              }}
            >
              Reset
            </MainButton>
          </div>
          <div>
            <MainButton
              disabled
              style={{
                marginRight: '15px',
                background: 'transparent',
                color: 'black',
              }}
            >
              Results: {totalResults}
            </MainButton>
            <MainButton
              onClick={() => downloadCSV(data)}
              style={{ marginRight: '5px' }}
            >
              Filtered CSV
            </MainButton>
            <MainButton
              onClick={() => downloadCSV()}
              style={{ marginRight: '5px' }}
            >
              CSV
            </MainButton>
          </div>
        </MainRow>
        <FoldableTable
          filterable
          data={data}
          columns={columns}
          onFetchData={onFetchData}
          manual
          getTrProps={(_, info) => ({
            style: {
              background: info?.row?._original?.invalid
                ? 'rgba(255, 0, 68, .5)'
                : '',
            },
          })}
          loading={isLoading}
          pages={Math.ceil(completeApiData.length / pageSize)}
          showPaginationTop={true}
          className="-striped -highlight"
          pageSizeOptions={[2, 10, 20, 25, 50, 100, 200, 500, 1000]}
        />
      </PaddedContainer>
      <CreateReimbursementModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        header="Create Reimbursement Request"
        onComplete={fetchData}
        showExceptionalReimbursement={showExceptionalReimbursement}
      />
      <FileLinks
        orderId={urlModalId}
        isOpen={!!urlModalId}
        onClose={() => setUrlModalId('')}
      />
      <OfflineCashgramModal
        isOpen={!!offlineCashgramId}
        onClose={() => setOfflineCashgramId(null)}
        onComplete={fetchData}
        orderId={offlineCashgramId}
      />
      {!!labCashgramId ? (
        <LabCashgramModal
          isOpen={!!labCashgramId}
          onClose={() => setLabCashgramId(null)}
          onComplete={fetchData}
          orderId={labCashgramId}
        />
      ) : (
        <></>
      )}
    </OuterComponent>
  );
};

export default ExceptionalReimbursement;
